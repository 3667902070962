/* Reset some default browser styles */
body,
h1,
p {
  margin: 0;
  padding: 0;
}

/* Define color variables */
:root {
  --primary-color: #fbf6ee; /* Primary color extracted from the logo */
  --secondary-color: #65b741; /* Secondary color extracted from the logo */
  --primary-color-dark: darkgray;
  --secondary-color-dark: #dede50;
  /* Add more as needed */
}

/* Apply a background color to the body */
body {
  font-family: Arial, sans-serif;
  background-color: var(--primary-color);
  /* color: var(--secondary-color);*/
}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
}

/* Style table headers and data cells */
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Style table headers */
th {
  background-color: #4caf50;
  color: white;
}

.separator-row {
  text-align: center; /* Center the text */
  background-color: #f2f2f2; /* Light grey background */
  font-weight: bold; /* Make the text bold */
  padding: 10px; /* Add some padding */
  /* Add more styles as needed */
}

/* Style the header */
.header {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 5px;
  text-align: center;
}

.reactModalContent {
  position: absolute;
  border: 1px solid gray;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 20px;

  top: 20%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-height: 90vh; /* Limit the height, with scroll for overflow */
  overflow: auto; /* Allow scroll if content is too long */
  /* Other styles as needed */
}

.player-cell {
  display: flex;
  align-items: center;
}

.player-portrait {
  width: 40px;
  height: 50px;
  margin-right: 10px;
}

.player-name {
  margin-right: 10px;
}

.player-flag {
  width: 51px;
  height: 30px;
  margin-right: 10px;
}

.player-nationality {
  margin-right: 10px;
}

.club-portrait {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* Animation for pop-up effect */
@keyframes popUpAnimation {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-container {
  width: 25%;
  min-width: 400px;
  background-color: var(--primary-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  animation: popUpAnimation 0.3s ease-out;
}
