@font-face {
    font-family: "MontserratAlternates";
    src: url("/public/fonts/Montserrat_Alternates/MontserratAlternates-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "MontserratAlternates";
    src: url("/public/fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "MontserratAlternates";
    src: url("/public/fonts/Montserrat_Alternates/MontserratAlternates-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "MontserratAlternates";
    src: url("/public/fonts/Montserrat_Alternates/MontserratAlternates-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}